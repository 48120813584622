
body{
  background-color: black;
}
.ScratchCard__Canvas {
  width: 235px;
  height: 235px;
  margin-left: 67px;
  /* /* margin-right: auto; */
}
.container-body {
  height: 9000px;
}
.spinner {
  width: 140px;
  height: 140px;
  background-color: cornflowerblue;
  margin: auto;
  margin-top: 55%;
}
.bg-bluecolor{
  background-color: rgb(23, 26, 43 );
}

@media (min-width: 375px) {
  .xl\:w-1\/4 {
      width: 43%;
  }
}



@media (min-width: 375px) {
  .xl\:max-w-2xl {
      max-width: 23rem;
      max-height: 593px;
  }
}

@media (min-width: 1024px) {
  .lg\:max-w-3xl {
      max-width: 23rem;
     
  }
}



